import React from "react";

import { Box, Container, Typography } from "@mui/material";
import styled from "@emotion/styled";

const BoxArea = styled(Box)(({ theme, reverse }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "1rem auto",
  maxWidth: "1100px",
  [theme.breakpoints.up("md")]: {
    flexDirection: reverse % 2 === 0 ? "row" : "row-reverse",
    gap: "1.5rem",
  },
}));

const Img = styled("img")(({ theme }) => ({
  width: "22rem",
  objectFit: "cover",
  marginBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "17rem",
  },
  [theme.breakpoints.up("xl")]: {
    width: "24rem",
  },
}));

const BoxImgtext = ({ props, title }) => {
  return (
    <Container
      sx={{
        marginTop: "5rem",
      }}
    >
      {title ? (
        <Typography
          sx={{
            backgroundColor: "#00a7d3",
            width: "fit-content",
            margin: "0 auto",
            padding: "3px 10px",
            color: "white",
          }}
          fontWeight={400}
          variant="h5"
          textAlign="center"
          color="initial"
        >
          {title}
        </Typography>
      ) : (
        ""
      )}
      <br />
      {props?.map(({ title, description, image }, idx) => (
        <BoxArea key={idx} reverse={idx}>
          <Img src={image} />
          <Box
            sx={{
              textAlign: { xs: "center", md: "start" },
            }}
          >
            <Typography variant="h5" fontWeight={700} color="initial">
              {title}
            </Typography>
            <Typography variant="subtitle1" color="initial">
              {description}
            </Typography>
          </Box>
        </BoxArea>
      ))}
    </Container>
  );
};

export default BoxImgtext;
