import React, { Fragment } from "react";

import { Box, Container, Typography } from "@mui/material";
import styled from "@emotion/styled";

import parse from "html-react-parser";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    textAlign: "justify",
  },
}));

const Title2 = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.h6.fontSize,
    textAlign: "justify",
}));

const Li = styled("li")({
  fontFamily: "Now",
  fontSize: "1.3rem",
});

const Img = styled("img")(({ theme }) => ({
  width: "20rem",
  objectFit: "contain",
  margin: "0 auto",
  [theme.breakpoints.up("md")]: {
    width: "30rem",
  },
  [theme.breakpoints.up("xl")]: {
    width: "24rem",
  },
}));

const Information = ({ props }) => {
  return (
    <Container>
      <Title>{props?.title}</Title>
      <ul>
        {props?.list?.map(({ title }, idx) => (
          <Li key={idx}>{title}</Li>
        ))}
      </ul>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: 'column', md: 'row' },
          gap: '5rem',
          margin: '3rem 0',
        }}
      >
        <Img src="https://res.cloudinary.com/dpkaiokho/image/upload/v1657906021/Agile%20Innova/call_i1rd7n.png" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {props?.description?.map(({ title }, idx) => (
            <Fragment key={idx}>
              <Title2>{parse(title)}</Title2>
              <br />
            </Fragment>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Information;
