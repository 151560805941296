import React from 'react'
import styled from '@emotion/styled';

import { Instagram, Twitter, Facebook, LinkedIn, YouTube } from '@mui/icons-material';
import { Box, Container, Grid, Link as LinkM, IconButton } from '@mui/material';
import { Link } from 'react-router-dom'


const Item = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    [theme.breakpoints.up('sm')]: {
        alignItems: 'flex-start'
    }
}))

const Social = styled(Box)(({ theme }) =>({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-start'
    },
    flexWrap: 'wrap',
    gap: '.5rem'
}))

// const Img = styled('img')(({ theme }) => ({
//     margin: 'auto',
//     display: 'block',
//     [theme.breakpoints.up('sm')]: {
//         margin: '0'
//     },
//     [theme.breakpoints.up('lg')]: {
//         margin: 'auto'
//     }
// }))

const Footer = () => {
  return (
    <footer>
        <Box
            sx={{
                backgroundColor: '#00a7d3',
                fontFamily: 'Open Sans',
                fontWeight: '800',
                fontSize: '15px',
                color: 'white',
            }}
        >  
            <Container maxWidth='xl'>
                <Grid container rowSpacing={3} pb='1.3rem' justifyContent={'center'}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Item>
                            <Link
                                sx={{
                                    textDecoration: 'none'
                                }}
                                to='/academy'
                                color='#FFF'
                            >
                                Academy
                            </Link>
                            <Link
                                sx={{
                                    textDecoration: 'none'
                                }}
                                to='/factory-soft'
                                color='#FFF'
                            >
                                Fábrica de Software
                            </Link>
                            <Link
                                sx={{
                                    textDecoration: 'none'
                                }}
                                to='/blog'
                                color='#FFF'
                            >
                                Blog
                            </Link>
                            <LinkM
                                sx={{
                                    textDecoration: 'none'
                                }}
                                target='_blank'
                                href='https://docs.google.com/document/d/1hJ8-dwZSn3OTn7RMe1AwoKFthq6E2vk2Oqbx1c-BdYU/edit'
                                color='#FFF'
                            >
                                Créditos
                            </LinkM>
                        </Item>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Item>
                            <Link
                                sx={{
                                    textDecoration: 'none'
                                }}
                                to='/tax-regimen'
                                color='#FFF'
                            >
                                Trámite de régimen tributario especial
                            </Link>
                            <LinkM
                                sx={{
                                    textDecoration: 'none'
                                }}
                                target='_blank'
                                href='https://storage.googleapis.com/academia-geek-dev.appspot.com/general-assets/POLITICA%20DE%20PRIVACIDAD%20Agile%20Innova%202022.0e710baf.pdf'
                                color='#FFF'
                            >
                                Pólitica de privacidad
                            </LinkM>
                            <LinkM
                                sx={{
                                    textDecoration: 'none'
                                }}
                                target='_blank'
                                href='https://api.whatsapp.com/send?phone=+573217031795&text=Hola,%20quiero%20saber%20más%20sobre%20Agile%20Innova'
                                color='#FFF'
                            >
                                Contacto
                            </LinkM>
                        </Item>
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={3}>
                        <Img
                            width='144px'
                            src="https://res.cloudinary.com/dpkaiokho/image/upload/v1657830394/Agile%20Innova/logos/AgileInnova_v7zvm6.png"
                            alt="logo"
                        />
                            <Typography
                                variant="h6"
                                textAlign={{ xs: 'center', sm: 'start', lg: 'center' }}
                                color="primary.contrastText"
                            >
                                Innovación
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign={{ xs: 'center', sm: 'start', lg: 'center' }}
                                color="primary.contrastText"
                            >
                                100% libre de humo
                            </Typography>                        
                    </Grid> */}

                    <Grid item xs={12} sm={6} md={3}>
                        <Social>
                            <IconButton
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                aria-label="instagram-icon"
                                href='https://www.instagram.com/agileinnova/'
                                target='_blank'
                            >
                                <Instagram
                                    fontSize='large'
                                />
                            </IconButton>
                            <IconButton
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                aria-label="twitter-icon"
                                target='_blank'
                                href='https://twitter.com/agileinnova'
                            >
                                <Twitter
                                    fontSize='large'
                                    />
                            </IconButton>
                            <IconButton
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                aria-label="facebook-icon"
                                target='_blank'
                                href='https://www.facebook.com/agileinnova-106397675475627'
                            >
                                <Facebook
                                    fontSize='large'
                                />
                            </IconButton>
                            <IconButton
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                aria-label="linkedin-icon"
                                target='_blank'
                                href='https://www.linkedin.com/company/agileinnova/'
                            >
                                <LinkedIn
                                    fontSize='large'
                                />
                            </IconButton>
                            <IconButton
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                aria-label="youtube-icon"
                                target='_blank'
                                href='https://youtube.com/channel/UCtnSSx5_WIAWw6BroblGoKQ'
                            >
                                <YouTube
                                fontSize='large'
                            />
                            </IconButton>
                        </Social>
                        
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </footer>
  )
}

export default Footer;