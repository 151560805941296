import React from 'react';

import { Box, Container, Typography } from '@mui/material';
import styled from '@emotion/styled';

const Img = styled('img')(({ theme }) => ({
    width: '550px',
    [theme.breakpoints.down('md')]: {
        width: '350px'
    }
}))

const Text = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}))

const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}))

const BoxTime = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    gap: '2rem',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}))

const BoxTime2 = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}))


const TimeLine = ({ props }) => {
  return (
    <Container sx={{ marginTop: '5rem'}}>
            <Typography
                sx={{
                    backgroundColor: '#00a7d3',
                    width: 'fit-content',
                    margin: '0 auto',
                    padding: '3px 10px',
                    color: 'white'
                }}
                fontWeight={400}
                variant="h5"
                textAlign='center'
                color="initial"
            >
                Un día de entrenamiento
            </Typography>
            <br />
            <BoxTime sx={{ maxWidth: '1100px', margin: '0 auto' }}>
                <Img src={ props?.step1?.image } alt='...' width='500' />
                <Box>
                {props?.step1?.contents.map( ({ title, values }, idx )=> (
                    <Box key={ idx }>
                        <Title variant="h5" color="initial" fontWeight={700}>
                            { title }
                        </Title>
                        <Box>
                        {values.map(( value, idx ) => (
                            <Text key={ idx } variant="subtitle1" color="initial">
                                { value }
                            </Text>
                        ))}
                        </Box>
                        <br />
                    </Box>
                ))}
                </Box>
            </BoxTime>
            <br />
            <BoxTime2 sx={{ maxWidth: '1100px', margin: '0 auto' }}>
                <Img src={ props?.step2?.image } alt='...' width='500' />
                <Box>
                {props?.step2?.contents.map( ({ title, values }, idx )=> (
                    <Box key={ idx }>
                        <Title variant="h5" color="initial" fontWeight={700}>
                            { title }
                        </Title>
                        <Box>
                        {values.map(( value, idx ) => (
                            <Text key={ idx } variant="subtitle1" color="initial">
                                { value }
                            </Text>
                        ))}
                        </Box>
                        <br />
                    </Box>
                ))}
                </Box>
            </BoxTime2>
    </Container>
  )
}

export default TimeLine;