import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { AppBar, Link as LinkM } from '@mui/material';
import { NavLink, Link, useLocation } from 'react-router-dom'
import HideOnScroll from '../../Modules/HidenAppbar';

const Navbar = ({ props, value, setValue }) => {
  // const logo = props?.imageNav
  const pages = props?.navBar
  const [anchorElNav, setAnchorElNav] = useState(null);
  const location = useLocation()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  let activeStyle = {
    textDecoration: "2px underline white",
    textDecorationThickness: '5px',
  };

  useEffect(() => {
  }, [location])
  

  return (
    <>
    <HideOnScroll {...props}>
      <AppBar
      elevation={0}
        sx={{
          zIndex: '2',
          backgroundColor: "rgb(0, 167, 211)"
          }}
        >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
          <Box>
            <Link
                to='/'
            >
                <img src='https://res.cloudinary.com/dpkaiokho/image/upload/v1659551398/Agile%20Innova/logos/Agile_blanco-bg_ucfvmw.png' alt="logo" width={'90px'}/>
            </Link>
          </Box> 
              <Box sx={{ 
                flexGrow: 1,
                display: { xs: 'flex', md: "none" },
                justifyContent: "flex-end"
                }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              {/* Menu Mobile */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  justifyContent: { sx: 'flex-end' },
                }}
              >
                {pages?.map((page, index) => (
                <Link
                sx={{
                  textDecoration: 'none',
                  color: '#000'
                }}
                to={ page.url }
                key={index}>
                  <MenuItem 
                    onClick={handleCloseNavMenu}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontWeight: "800",
                        fontSize: '20px'
                      }}
                    >
                      { page.name }
                    </Typography>
                  </MenuItem>
                </Link>
                ))}
              </Menu>
            </Box>
        
            <Box 
              sx={{ 
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: "flex-end"
              }}
            >
              {pages?.map((page, index) => {
                if(location?.pathname === '/' && page?.url === '/academy'){
                  return (
                    <LinkM
                      href='#agileTitle'
                      key={index}
                      sx={{ textDecoration: 'none' }}
                    >
                      <Button
                        key={page}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'white', display: 'block', fontSize: '18px', fontWeight: 800 }}
                      >
                        { page.name }
                      </Button>
                    </LinkM>
                  )
                } else {
                  return (
                    <NavLink
                      to={ page.url }
                      key={index}
                      style={({ isActive }) => isActive ? activeStyle : undefined}
                    >
                      <Button
                        key={page}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'white', display: 'block', fontSize: '18px', fontWeight: 800 }}
                      >
                        { page.name }
                      </Button>
                    </NavLink>
                  )
                }
            })}
            </Box>
            <Box>
              <Button
                target="_blank"
                href="https://academy.agileinnova.org/"
                color='secondary'
                sx={{
                    display: { sx: "initial", md: "none"},
                    fontFamily: "Open Sans",
                    fontWeight: "800"
                }}
                variant="contained"
              >
                Aplica
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      </HideOnScroll>
    </>
  )
}

export default Navbar;