export const data = {
  "principalPageData": {
    "headerContent": {
      "imageNav": "https://res.cloudinary.com/dpkaiokho/image/upload/v1660142074/Agile%20Innova/Home_transparente_enzlhg.png",
      "navBar": [
        {
          "id": "inicio",
          "name": "Inicio",
          "url": "/"
        },
        {
          "id": "academy",
          "name": "Academy",
          "url": "/academy"
        },
        {
          "id": "factory",
          "name": "Fábrica de Software",
          "url": "/factory-soft"
        }
      ]
    },
    "mainContent": {
      "title": "Somos un Bootcamp/Fábrica de Software en el que puedes formarte y entrenarte como programador de aplicaciones Web, desarrollando las habilidades que las empresas están buscando.",
      "description": "Simulamos el entorno de trabajo en una empresa, con la mentoría de desarrolladores expertos y proyectos reales para que potencies tus capacidades mientras aprendes y ganas experiencia",
      "btnAction": "Aplica",
      "btnUrl": "https://academy.agileinnova.org",
      "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1660142074/Agile%20Innova/Home_transparente_enzlhg.png"
    },
    "training": [
      {
        "id": "explorer",
        "title": "Explorer <br /> (Desde cero)",
        "time": "Depende de ti",
        "swing": "A tu propio ritmo",
        "next": "Conocerás lo básico para definir si quieres y puedes seguir a un siguiente nivel de entrenamiento",
        "option": "Siguiente paso",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658524791/Agile%20Innova/imageonline-co-transparentimage_1_eoyy0x.png"
      },
      {
        "id": "fundamentals",
        "title": "Fundamentals",
        "time": "30 días",
        "swing": "Media - Mínimo deberás dedicar 20 horas semanales",
        "next": "Estarás mejor preparado para aplicar a un entrenamiento avanzado",
        "option": "Siguiente paso",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837384/Agile%20Innova/friend_uu2bnz.png"
      },
      {
        "id": "frontend",
        "title": "Frontend Junior",
        "time": "12 semanas",
        "swing": "Alta - Mínimo 6 horas diarias",
        "next": "Alta - +78% en el primer més luego de culminar",
        "option": "Tasa de empleo",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837385/Agile%20Innova/work_xfvco6.png"
      },
      {
        "id": "backend",
        "title": "Backend Junior",
        "time": "12 semanas",
        "swing": "Alta - Mínimo 6 horas diarias",
        "next": "Alta - +85% en el primer més luego de culminar",
        "option": "Tasa de empleo",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837385/Agile%20Innova/work2_dxglbt.png"
      },
      {
        "id": "tester",
        "title": "Tester Junior",
        "time": "12 semanas",
        "swing": "Alta - Mínimo 6 horas diarias",
        "next": "Alta - +85% en el primer més luego de culminar",
        "option": "Tasa de empleo",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837384/Agile%20Innova/pracrtice_ywlwum.png"
      }
    ],
    "training2": {
      "initial": [
        {
          "id": "explorer",
          "title": "Explorer <br /> (Desde cero)",
          "time": "Depende de ti",
          "swing": "A tu propio ritmo",
          "next": "Conocerás lo básico para definir si quieres y puedes seguir a un siguiente nivel de entrenamiento",
          "option": "Siguiente paso",
          "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658524791/Agile%20Innova/imageonline-co-transparentimage_1_eoyy0x.png"
        },
        {
          "id": "fundamentals",
          "title": "Fundamentals",
          "time": "30 días",
          "swing": "Media - Mínimo deberás dedicar 20 horas semanales",
          "next": "Estarás mejor preparado para aplicar a un entrenamiento avanzado",
          "option": "Siguiente paso",
          "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837384/Agile%20Innova/friend_uu2bnz.png"
        }
      ],
      "official": [
        {
          "id": "frontend",
          "title": "Frontend Junior",
          "time": "12 semanas",
          "swing": "Alta - Mínimo 6 horas diarias",
          "next": "Alta - +78% en el primer més luego de culminar",
          "option": "Tasa de empleo",
          "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837385/Agile%20Innova/work_xfvco6.png"
        },
        {
          "id": "backend",
          "title": "Backend Junior",
          "time": "12 semanas",
          "swing": "Alta - Mínimo 6 horas diarias",
          "next": "Alta - +85% en el primer més luego de culminar",
          "option": "Tasa de empleo",
          "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837385/Agile%20Innova/work2_dxglbt.png"
        },
        {
          "id": "tester",
          "title": "Tester Junior",
          "time": "12 semanas",
          "swing": "Alta - Mínimo 6 horas diarias",
          "next": "Alta - +85% en el primer més luego de culminar",
          "option": "Tasa de empleo",
          "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837384/Agile%20Innova/pracrtice_ywlwum.png"
        }
      ]
    },
    "benefit": [
      {
        "title": "Un entorno realista, tal como en una empresa",
        "description": "Desde el día uno trabajarás en un equipo de desarrollo, tendrás un líder, un proyecto asignado, requerimientos, fechas de entrega y todo lo que necesitas para adquirir experiencia mientras aprendes",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837385/Agile%20Innova/study_vagjqn.png"
      },
      {
        "title": "Mentores que son desarrolladores",
        "description": "Cuando nuestros mentores no están liderando entrenamientos están construyendo productos de software en nuestra fábrica, lo que implica que todo lo que te enseñarán es pertinente y actual",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837384/Agile%20Innova/pracrtice_ywlwum.png"
      },
      {
        "title": "Conexión con empresas contratantes",
        "description": "Más de 80 empresas a nivel LATAM ya han contratado egresados de nuestro entrenamiento. Te ayudaremos a encontrar tu próxima oportunidad",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657837385/Agile%20Innova/friend2_luw4ze.png"
      }
    ],
    "amounts": [
      {
        "amount": "+200",
        "description": "Egresados de entrenamientos avanzados"
      },
      {
        "amount": "+80%",
        "description": "de los egresados trabaja actualmente en desarrollo web"
      },
      {
        "amount": "2 meses",
        "description": "Es el tiempo promedio en que los egresados encuentran o mejoran su empleo"
      }
    ],
    "experience": [
      {
        "title": "Trabajo en equipo",
        "description": "Harás parte de una comunidad de aprendizaje, que incluye otros aprendices, mentores, líderes de desarrollo, empresarios y asesores.",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657906020/Agile%20Innova/friend3_axai90.png"
      },
      {
        "title": "Mentorías en vivo",
        "description": "Contarás con espacios de acompañamiento con expertos para potenciar tanto tus habilidades técnicas como otras que necesitarás para aumentar tu nivel profesional.",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657906020/Agile%20Innova/meeting_efkkl9.png"
      },
      {
        "title": "Design Thinking",
        "description": "Aprenderás a identificar necesidades de usuarios y a proyectar funcionalidades para tus productos. No solo mejorarás técnicamente sino que podrás ser realmente innovador.",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657906020/Agile%20Innova/work3_ehmkph.png"
      }
    ],
    "timeLine": [
      {
        "title": "Primer bloque",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657906021/Agile%20Innova/call_i1rd7n.png",
        "content": [
          {
            "name": "Encuentro con el mentor"
          },
          {
            "name": "Daily"
          },
          {
            "name": "Revisión de temas"
          }
        ]
      },
      {
        "title": "Segundo bloque",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657906020/Agile%20Innova/break_ewpstt.png",
        "content": [
          {
            "name": "Aprendizaje colaborativo"
          }
        ]
      },
      {
        "title": "Break",
        "content": [
          {
            "name": "Pausa - café"
          }
        ]
      },
      {
        "title": "Tercer bloque",
        "content": [
          {
            "name": "Aprendizaje colaborativo"
          },
          {
            "name": "Retos individuales y/o en equipo"
          }
        ]
      },
      {
        "title": "Pausa",
        "content": [
          {
            "name": "Descanso - Alimentación"
          }
        ]
      },
      {
        "title": "Cuarto bloque",
        "content": [
          {
            "name": "Aprendizaje autónomo"
          },
          {
            "name": "Solución de retos individuales y por equipos"
          }
        ]
      }
    ],
    "timeline2": {
      "step1": {
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657906021/Agile%20Innova/call_i1rd7n.png",
        "contents": [
          {
            "title": "Primer bloque",
            "values": ["Encuentro con el mentor", "Daily", "Revisión de temas"]
          },
          {
            "title": "Segundo bloque",
            "values": ["Aprendizaje colaborativo"]
          },
          {
            "title": "Break",
            "values": ["Pausa - Café"]
          },
          {
            "title": "Tercer bloque",
            "values": [
              "Aprendizaje colaborativo",
              "Retos individuales y/o en equipos"
            ]
          }
        ]
      },
      "step2": {
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657906020/Agile%20Innova/break_ewpstt.png",
        "contents": [
          {
            "title": "Pausa",
            "values": ["Descanso - Alimentación"]
          },
          {
            "title": "Cuarto bloque",
            "values": [
              "Aprendizaje autónomo",
              "Solución de retos individuales y por equipos"
            ]
          }
        ]
      }
    },
    "alert": "Haz parte de una industria en la que siempre podrás generar ingresos",
    "graduates": [
      {
        "image": "",
        "info": "Juan - 23 - Colombia",
        "work": "Desarrollador en sofka",
        "testimony": "Encontré trabajo en 15 días y hoy puedo dar calidad de vida a mi familia"
      },
      {
        "image": "",
        "info": "Erika - 30 - Venezuela",
        "work": "Desarrollador en Using",
        "testimony": "Empecé desde cero y los contenidos fueron muy útiles para acercarme a la programación. Ahora soy Desarrolladora Frontend con muy buena proyección"
      },
      {
        "image": "",
        "info": "Jose - 36 - Colombia",
        "work": "Desarrollador en Livtree",
        "testimony": "Tenía algo de conocimiento pero poca experiencia, me ayudó mucho encontrar un entorno real para aprender haciendo"
      }
    ],
    "allies": [
      {
        "name": "Accenture",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/1024px-Accenture.svg_uwrr42.png"
      },
      {
        "name": "ACDI",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/acdivoca_logo-ligth_ikr9fu.png"
      },
      {
        "name": "ASPEN",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/ASPEN-GOYN-GLOBAL-large-1-400x208_blehbe.png"
      },
      {
        "name": "Bancolombia",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/Bancolombia-logo_gcxae7.png"
      },
      {
        "name": "Celsia",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/Celsia_sj8dkd.png"
      },
      {
        "name": "Ifood",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/IFood_logo.svg_rnbqgy.png"
      },
      {
        "name": "Internet Society Foundation",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/Internet-Society-Foundation-Logo_xopu2z.png"
      },
      {
        "name": "COMFAMA",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/logo-comfama-01-2_ic0pej.png"
      },
      {
        "name": "COMFENALCO",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/LOGO-COMFENALCO-PREFERENCIAL_nonp7a.png"
      },
      {
        "name": "Google CLoud",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/Logo-Google-Cloud_idyhsx.png"
      },
      {
        "name": "Alcaldia de Medellin",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/logo-sapiencia-2020_horizontal_wcd8xf.png"
      },
      {
        "name": "Ruta-n",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658146503/Agile%20Innova/logos/Ruta-N_lsvhni.png"
      }
    ]
  },
  "academy": {
    "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658157495/Agile%20Innova/logos/agile-innova-academy.png",
    "tecnologies": [
      {
        "name": "CSS3",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231227/Agile%20Innova/logos/CSS3_logo_and_wordmark.svg_ibjqlm.png"
      },
      {
        "name": "HTML5",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231228/Agile%20Innova/logos/html5-logo_qd5moy.png"
      },
      {
        "name": "JavaScript",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231227/Agile%20Innova/logos/javascript-logo-1_g82nn9.png"
      },
      {
        "name": "ReactJS",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231228/Agile%20Innova/logos/react_vupbpm.png"
      },
      {
        "name": "NodeJS",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231228/Agile%20Innova/logos/nodejs-logo-png-transparent_n8owgd.png"
      },
      {
        "name": "Bootstrap",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231227/Agile%20Innova/logos/bootstrap-logo_w9atyg.png"
      },
      {
        "name": "MongoDB",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231228/Agile%20Innova/logos/MongoDB_Logo.svg_u9xivi.png"
      },
      {
        "name": "Firebase",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231227/Agile%20Innova/logos/firebase_wb92vf.png"
      },
      {
        "name": "Google Cloud",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231228/Agile%20Innova/logos/Logo-Google-Cloud_uywic0.png"
      },
      {
        "name": "Github",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658231227/Agile%20Innova/logos/GitHubLogo_czhlem.png"
      }
    ],
    "information": {
      "title": "Nuestra fábrica de software será tu campo de entrenamiento, y nuestros desarrolladores serán tus mentores.",
      "list": [
        {
          "title": "Simulamos un entorno de trabajo en una empresa"
        },
        {
          "title": "Te ofrecemos mentoría de desarrolladores expertos"
        },
        {
          "title": "Trabajarás en proyectos reales"
        },
        {
          "title": "Ganarás experiencia mientras aprendes"
        },
        {
          "title": "Te ayudaremos a optimizar tu portafolio, prepararte para entrevistas y te conectaremos con empresas contratantes"
        }
      ],
      "description": [
        {
          "title": "Aplicar es gratis, y no representa un compromiso de tu parte o de la nuestra para iniciar un entrenamiento."
        },
        {
          "title": "No tendrás que pagar nada hasta que seas <strong class='strong'>aceptado</strong> y aún en ese momento te daremos facilidades, Lo que más nos interesa es que logres formarte como un programador web."
        },
        {
          "title": "Puedes aplicar a una de las becas (100% de descuento) que tengamos disponibles (si cumples los requisitos de nuestros cooperantes) o solicitar financiación para pagar tu entrenamiento de la forma que te sea más cómoda."
        }
      ],
      "courses": {
        "text": "Harás parte de la comunidad de desarrollo de Agile Innova Academy y accederás a:",
        "course": [
          {
            "title": "Explorer",
            "description": "Es un entrenamiento autónomo y gratuito a través de nuestra plataforma en línea. Aprenderás conceptos básicos de programación como algoritmos, variables, lenguajes de programación y herramientas para iniciar tu carrera como desarrollador web.",
            "price": "Gratis",
            "training": "explorer",
            "ofert": [],
            "list": [
              {
                "title": "Un canal privado de Slack para resolver dudas."
              },
              {
                "title": "Posibilidad de acceder a descuentos en productos, servicios y eventos de Agile Innova."
              },
              {
                "title": "Estar entre las primeras personas en enterarse de becas y tener prioridad para aplicar."
              }
            ]
          },
          {
            "title": "Fundamentals",
            "description": "Es un entrenamiento a través sesiones en línea lideradas por mentores, que incluye competencias básicas de <strong class='strong'>Frontend</strong>, <strong class='strong'>Backend</strong> y <strong class='strong'>Testing</strong>, de forma que puedas experimentar cada uno de estos roles. Podrás experimentar nuestra metodología de entrenamiento y formación, que simula el entorno de trabajo de una empresa de desarrollo de software y de acuerdo con los aprendizajes que lograste, puedas definir a qué rol o curso deseas continuar con tu formación.",
            "price": "1.130.000",
            "training": "fundamentals",
            "ofert": [],
            "list": [
              {
                "title": "Un canal privado de Slack para resolver dudas."
              },
              {
                "title": "Invitaciones gratuitas a eventos exclusivos, presenciales y en línea, para la comunidad de Agile Innova."
              },
              {
                "title": "Posibilidad de acceder a descuentos en productos, servicios y eventos de Agile Innova."
              },
              {
                "title": "Estar entre las primeras personas en enterarse de becas y tener prioridad para aplicar."
              }
            ]
          },
          {
            "title": "Frontend Junior",
            "description": "Es un entrenamiento a través de sesiones en línea lideradas por mentores, en el que aprenderás a construir interfaces potentes e innovadoras a través de tu participación en proyectos reales de una fábrica de software. Perfilarás usuarios, proyectarás funcionalidades, construirás prototipos, harás validaciones y construirás un producto haciendo parte de un equipo de desarrollo, tal como si ya estuvieras trabajando en una empresa de desarrollo de software.",
            "price": "7.521.000",
            "training": "frontend",
            "plus": "Si superas satisfactoriamente este entrenamiento, te promovemos a través de nuestra red de empresas para que consigas trabajo",
            "ofert": [
              {
                "terms": "Si apruebas Fundamentals en menos de 30 días",
                "percentage": 20
              },
              {
                "terms": "Si cumples los requisitos para una beca, aplica para descubrirlo",
                "percentage": 100
              }
            ],
            "list": [
              {
                "title": "Aplicando las metodologías ágiles y conocimientos de programación aprendidos, deberás resolver retos reales, algunos de forma individual y otros en grupo, donde se te evaluará el desempeño tal como ocurre en una empresa de desarrollo de software."
              },
              {
                "title": "Un canal privado de Slack para resolver dudas. Invitaciones gratuitas a eventos exclusivos, presenciales y en línea, para la comunidad de Agile Innova."
              },
              {
                "title": "Posibilidad de acceder a descuentos en productos, servicios y eventos de Agile Innova."
              },
              {
                "title": "Estar entre las primeras personas en enterarse de becas y tener prioridad para aplicar."
              }
            ]
          },
          {
            "title": "Backend Junior",
            "description": "Es un entrenamiento a través de sesiones en línea lideradas por mentores, en el que aprenderás a programar funciones, procedimientos o aplicaciones que procesen información del lado del servidor en proyectos reales de una fábrica de software. Perfilarás usuarios, proyectarás y documentarás servicios,construirás prototipos, harás validaciones y construirás un producto haciendo parte de un equipo de desarrollo.",
            "price": "8.758.000",
            "training": "backend",
            "plus": "Si superas satisfactoriamente este entrenamiento, te promovemos a través de nuestra red de empresas para que consigas trabajo",
            "ofert": [
              {
                "terms": "Si culminas Fundamentals en menos de 30 días o si aprobaste Frontend Junior",
                "percentage": 20
              },
              {
                "terms": "Si cumples los requisitos para una beca, aplica para descubrirlo",
                "percentage": 100
              }
            ],
            "list": [
              {
                "title": "Aplicando las metodologías ágiles y conocimientos de programación aprendidos, deberás resolver retos reales, algunos de forma individual y otros en grupo, donde se te evaluará el desempeño tal como ocurre en una empresa de desarrollo de software."
              },
              {
                "title": "Un canal privado de Slack para resolver dudas. Invitaciones gratuitas a eventos exclusivos, presenciales y en línea, para la comunidad de Agile Innova."
              },
              {
                "title": "Posibilidad de acceder a descuentos en productos, servicios y eventos de Agile Innova."
              },
              {
                "title": "Estar entre las primeras personas en enterarse de becas y tener prioridad para aplicar."
              }
            ]
          },
          {
            "title": "Tester Junior",
            "description": "Es un entrenamiento a través de sesiones en línea lideradas por mentores, en el que aprenderás a detectar y corregir errores en los productos web, promoverás su correcto funcionamiento y ayudaras a mejorar su calidad. Harás pruebas con productos reales de una fábrica de software siendo parte de un equipo de desarrollo.",
            "price": "7.521.000",
            "training": "tester",
            "plus": "Si superas satisfactoriamente este entrenamiento, te promovemos a través de nuestra red de empresas para que consigas trabajo",
            "ofert": [
              {
                "terms": "Si culminas Fundamentals en menos de 30 días o si aprobaste Frontend o Backend Junior",
                "percentage": 20
              },
              {
                "terms": "Si cumples los requisitos para una beca, aplica para descubrirlo",
                "percentage": 100
              }
            ],
            "list": [
              {
                "title": "Aplicando las metodologías ágiles y conocimientos de programación aprendidos, deberás resolver retos reales, algunos de forma individual y otros en grupo, donde se te evaluará el desempeño tal como ocurre en una empresa de desarrollo de software."
              },
              {
                "title": "Un canal privado de Slack para resolver dudas. Invitaciones gratuitas a eventos exclusivos, presenciales y en línea, para la comunidad de Agile Innova."
              },
              {
                "title": "Posibilidad de acceder a descuentos en productos, servicios y eventos de Agile Innova."
              },
              {
                "title": "Estar entre las primeras personas en enterarse de becas y tener prioridad para aplicar."
              }
            ]
          }
        ]
      }
    }
  },
  "factory-soft": {
    "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1660141864/Agile%20Innova/fabrica_sin_fondo_zsjusp.png",
    "factoryContent": {
      "title": "Ayudamos a que tu empresa sea más competitiva a través de innovación y conocimiento.",
      "description": "Tenemos equipos que incluyen desarrolladores expertos y aprendices para construir productos potentes, con calidad técnica y alto valor para los usuarios",
      "btnAction": "Hablemos",
      "btnUrl": "https://api.whatsapp.com/send?phone=+573217031795&text=Hola,%20quiero%20saber%20m%C3%A1s%20sobre%20Agile%20Innova",
      "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1660141864/Agile%20Innova/fabrica_sin_fondo_zsjusp.png"
    },
    "areas": [
      {
        "title": "Construcción integral",
        "description": "Construimos con tu empresa desde la ideación hasta el producto, pasando por prototipado, validaciones, pruebas y soporte. Aplicamos metodologías Agile en todo el proceso buscando siempre entregar valor al usuario.",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658419845/Agile%20Innova/Contrucci%C3%B3n_integral_pry9jc.png"
      },
      {
        "title": "Design Thinking",
        "description": "Usamos metodologías y herramientas validadas mundialmente para identificar necesidades de usuarios y proyectar las funcionalidades de tus productos que sean realmente innovadoras.",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1657906020/Agile%20Innova/work3_ehmkph.png"
      }
    ],
    "allies": [
      {
        "name": "Google Cloud",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658435644/Agile%20Innova/logos/Logo-Google-Cloud_arxtya.png"
      },
      {
        "name": "idream",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658435644/Agile%20Innova/logos/drimos_rwpcaj.png"
      },
      {
        "name": "Academia Geek",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658435644/Agile%20Innova/logos/Logo-AG-2021-140x98_rd4yzw.png"
      },
      {
        "name": "Makaia",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658435644/Agile%20Innova/logos/logo-makaia-web_qw6jxu.png"
      },
      {
        "name": "Inter",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658435644/Agile%20Innova/logos/Logo-Inter-Principal-01-200_q4uyja.png"
      },
      {
        "name": "Educación Futuro",
        "image": "https://res.cloudinary.com/dpkaiokho/image/upload/v1658435644/Agile%20Innova/logos/cropped-LogoEducacionFuturo_jqec8u.png"
      }
    ]
  },
  "taxRegimen": {
    "title": "Trámite de Régimen Tributario Especial",
    "description": "En los siguientes enlaces pueden ser consultados los archivos digitalizados que sustentan nuestra solicitud ante la DIAN para permanecer en Régimen Tributario Especial:",
    "years": [
      {
        "button": "2018",
        "urlButton": "https://agileinnovaco-my.sharepoint.com/:f:/g/personal/director_agileinnova_com_co/El6V_i2AqGlGvnpO5ZkJvsUBrTTRBwkkWzlEITdhefD03w?e=OXfXIq"
      },
      {
        "button": "2019",
        "urlButton": "#"
      },
      {
        "button": "2020",
        "urlButton": "https://agileinnovaco-my.sharepoint.com/:f:/g/personal/director_agileinnova_com_co/El4DLxUb6A5Fnfo_Fw-oLSwBT180XScgdhwQbS-zwmp1EA?e=Wbq4SY"
      },
      {
        "button": "2021",
        "urlButton": "https://agileinnovaco-my.sharepoint.com/:f:/g/personal/director_agileinnova_com_co/EmNaY6ttGglNiQI9xIcl65sB-NkeLgS6n3P92CnGG5bjdA?e=drzbhV"
      },
      {
        "button": "2022",
        "urlButton": "https://agileinnovaco-my.sharepoint.com/personal/director_agileinnova_com_co/_layouts/15/onedrive.aspx?ga=1&id=%2Fpersonal%2Fdirector%5Fagileinnova%5Fcom%5Fco%2FDocuments%2FDIAN%5FRTE%2F2021%2F2022"
      },
      {
        "button": "2023",
        "urlButton": "https://agileinnovaco-my.sharepoint.com/personal/director_agileinnova_com_co/_layouts/15/onedrive.aspx?ga=1&id=%2Fpersonal%2Fdirector%5Fagileinnova%5Fcom%5Fco%2FDocuments%2FDIAN%5FRTE%2F2021%2F2023"
      }
    ]
  }
}
