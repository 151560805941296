import React from 'react';
import Carousel from 'react-material-ui-carousel'

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Typography, Grid, Card, CardMedia } from '@mui/material'

import './styles.css'
// import { Link } from 'react-router-dom';

function Item(props) {
    const totalItems = props.item.Items.length ? props.item.Items.length : 3;
    let mediaLength;
    let gridCols;
    if (window.screen.availWidth <= 720) {
        mediaLength = 1
        gridCols = 12
    } else if (window.screen.availWidth <= 1024) {
        mediaLength = 2
        gridCols = 6
    }else if (window.screen.availWidth <= 1280) {
        mediaLength = 3
        gridCols = 4
    } else {
        mediaLength = totalItems
        gridCols = 3
    }

    let items = []

    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={gridCols} key={item.Name}>
                {/* <Link to={item?.Url ?? item?.url}> */}
                <CardMedia
                    className="Media"
                    image={item.Image}
                    title={item.Name}
                >
                    {/* <Typography className="MediaCaption">
                        {item.Name}
                    </Typography> */}
                </CardMedia>
                {/* </Link> */}
            </Grid>
        )
        items.push(media);
    }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                { items }
            </Grid>
        </Card>
    )
}

export default function Carrousel(){
    const items = [
        {
            // Name: "Electronics",
            // Caption: "Electrify your friends!",
            // contentPosition: "left",
            Items: [
                {
                    Name: "fundacion futuro",
                    // Url: "",
                    Image: "https://res.cloudinary.com/dpkaiokho/image/upload/v1664388423/Agile%20Innova/carrousel/Noticia_alianza-min_yum95i.png"
                },
                {
                    Name: "becas",
                    // Url: "",
                    Image: "https://res.cloudinary.com/dpkaiokho/image/upload/v1664388423/Agile%20Innova/carrousel/Becas_ilbc9x.png"
                },
                {
                    Name: "agile innova",
                    // Url: "",
                    Image: "https://res.cloudinary.com/dpkaiokho/image/upload/v1664388423/Agile%20Innova/carrousel/Agile_Innova_Llega_a_Bogota_emvuqh.png"
                },
                {
                    Name: "curso next",
                    // Url: "",
                    Image: "https://res.cloudinary.com/dpkaiokho/image/upload/v1664388422/Agile%20Innova/carrousel/Next.JS_ydiiqc.png"
                }
            ]
        }
    ]

    return (
        <div style={{ marginTop: "50px", color: "#494949", marginBottom: "50px" }}>
            <Typography
                variant='h4'
                sx={{
                    backgroundColor: '#00a7d3',
                    width: 'fit-content',
                    margin: '0 auto',
                    padding: '3px 10px',
                    color: 'white',
                    textAlign: 'center'
                }}
            >
                Noticias
            </Typography>
            <br/>
            <Carousel   
                NextIcon={<NavigateNextIcon/>}
                PrevIcon={<ArrowBackIosNewIcon />}
                navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
                indicatorContainerProps={{ style: {margin: "20px"} }}
                autoPlay={false}
                indicators={false}
                navButtonsAlwaysInvisible
            >
                {
                    items.map( (item, i) => <Item key={i} item={item} /> )
                }
            </Carousel>
        </div>
    )
}