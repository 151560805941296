import React from "react";
import ReactDOM from "react-dom/client";

import AppRoutes from "./Routes/AppRoutes";
import { UseContextProvider } from "./Context/UseContext";

import './Styles/fonts.css';
import './Styles/normalize.css'
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Styles/themeConfig";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ThemeProvider theme={ theme }>
            <UseContextProvider>
                <AppRoutes />
            </UseContextProvider>
        </ThemeProvider>
    </React.StrictMode>
);