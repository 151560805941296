import React, { Fragment, useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

import parse from "html-react-parser";
import getData from "../../Helpers/getData";
import { useLocation } from "react-router-dom";

import frontend from "../../Resources/Plan-de-estudios-Frontend.pdf";
import backend from "../../Resources/Plan-de-estudios-Backend.pdf";
import testing from "../../Resources/Plan-de-estudios-QATesting.pdf";
import explorer from "../../Resources/Plan-Explorer-Course.pdf";
import fundamentals from "../../Resources/Plan-de-estudios-Fundamentals.pdf";

import { data } from "../../mocks/db"

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  textAlign: "center",
  fontWeight: 400,
  backgroundColor: "#00a7d3",
  margin: "0 auto",
  width: "fit-content",
  color: "#FFF",
  padding: "3px 10px",
}));

const Img = styled("img")(({ theme }) => ({
  width: "17rem",
  display: "block",
  margin: "0 auto",
}));

const Span = styled("span")({
  display: "flex",
  alignItems: "flex-start",
  gap: "0.5rem",
  marginBottom: "0.5rem",
});

const Li = styled("li")({
  fontFamily: "Now",
  fontSize: "1.3rem",
});

const ButtonGreen = styled(Button)(({ theme }) => ({
  display: "inherit",
  width: "max-content",
  margin: "1.5rem 0",
  borderRadius: "10rem",
  padding: "0.7rem 2rem",
  fontSize: theme.typography.body1.fontSize,
  textTransform: "none",
  [theme.breakpoints.up("md")]: {},
}));

const StackOferts = styled(Stack)(({ theme }) => ({}));

const Course = ({ props, setOpen }) => {
  const [training_info, setTraining_info] = useState([]);
  const location = useLocation();

  const handleClickOpen = (title) => {
    switch (title) {
      case "Frontend Junior":
        setOpen({ bool: true, docu: frontend });
        break;
      case "Backend Junior":
        setOpen({ bool: true, docu: backend });
        break;
      case "Tester Junior":
        setOpen({ bool: true, docu: testing });
        break;
      case "Explorer":
        setOpen({ bool: true, docu: explorer });
        break;
      case "Fundamentals":
        setOpen({ bool: true, docu: fundamentals });
        break;
      default:
        break;
    }
  };

  const findTraining = async () => {
    setTraining_info(data.principalPageData.training)
    // try {
    //   const { training } = await getData(
    //     `${process.env.REACT_APP_API_URL_DEV}principalPageData`
    //   );
    //   setTraining_info(training);
    // } catch (err) {
    //   console.warn(err);
    // }
  };

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(`[id="${location.hash}"]`)?.scrollIntoView({
        block: "start",
        inline: "center",
        behavior: "smooth",
      });
    }, 700);
  }, [location]);

  useEffect(() => {
    findTraining();
  });

  return (
    <Container>
      {props?.course?.map(
        ({ title, description, price, ofert, list, plus }, idx) => (
          <Box
            id={`#${training_info[idx]?.id}`}
            key={idx}
            sx={{ margin: "2rem 0" }}
          >
            <Title>{title}</Title>
            <Paper
              elevation={3}
              sx={{
                marginTop: "1rem",
                padding: ".5rem",
                display: { md: "flex" },
                gap: "1rem",
              }}
            >
              <Box sx={{ width: "fit-content", marginLeft: ".5rem" }}>
                <Img src={training_info[idx]?.image} alt={title} />
                <Typography variant="h5" color="initial" textAlign={"center"}>
                  {price.toLowerCase() === "Gratis".toLowerCase()
                    ? "Gratis (en versión autónoma - sin mentoría) - Aplican cargos si se toma la versión con Mentoría (según disponibilidad de grupos)"
                    : `$${price} COP`}
                </Typography>
                <br />
                {ofert?.map(({ terms, percentage }, idx) => (
                  <StackOferts
                    key={idx}
                    direction={{ xs: "column", md: "row" }}
                    spacing={1}
                    maxWidth={{ xs: "332px", md: "370px" }}
                  >
                    <Typography
                      fontWeight={700}
                      variant="h5"
                      textAlign={{ xs: "center", md: "start" }}
                      width={{ md: "120px" }}
                    >
                      {percentage === 100 ? "Gratis" : percentage + " % OFF"}
                    </Typography>
                    <Typography
                      variant="body1"
                      textAlign={{ xs: "center", md: "start" }}
                      width={{ md: "250px" }}
                    >
                      {terms}
                    </Typography>
                    <br />
                  </StackOferts>
                ))}
                <Typography
                  variant="subtitle2"
                  textAlign="center"
                  margin="1rem 0"
                  color="initial"
                >
                  {plus}
                </Typography>
              </Box>
              {/* Container times */}
              <Box
                sx={{
                  width: "fit-content",
                  maxWidth: "370px",
                  marginLeft: ".5rem",
                }}
              >
                <Span>
                  <img
                    src="https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Duraci%C3%B3n_avf8us.png"
                    alt="time"
                    width={25}
                  />
                  <Typography variant="body1" color="initial">
                    {training_info[idx]?.time}
                  </Typography>
                </Span>
                <Span>
                  <img
                    src="https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Intensidad_max3pg.png"
                    alt="swing"
                    width={25}
                  />
                  <Typography variant="body1" color="initial">
                    {training_info[idx]?.swing}
                  </Typography>
                </Span>
                <Span>
                  <img
                    src="https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Tasa_de_empleo_meh93b.png"
                    alt="swing"
                    width={25}
                  />
                  <Typography variant="body1" color="initial">
                    {training_info[idx]?.option}: {training_info[idx]?.next}
                  </Typography>
                </Span>
              </Box>
              {/* Derscription */}
              <Box
                maxWidth={{ md: "40%" }}
                sx={{ marginRight: { md: ".5rem" } }}
              >
                <Typography variant="h6" color="initial" textAlign="justify">
                  {parse(description)}
                </Typography>
                <br />
                <Typography variant="h6" color="initial" textAlign="justify">
                  {props?.text}
                </Typography>
                <ul>
                  {list?.map(({ title }, idx) => (
                    <Fragment key={idx}>
                      <Li>{title}</Li>
                      <br />
                    </Fragment>
                  ))}
                </ul>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    justifyContent: { md: "space-around" },
                  }}
                >
                  <ButtonGreen
                    variant="contained"
                    color="secondary"
                    href={
                      idx === 0
                        ? "https://agileinnova.github.io/explorer-course-ai/"
                        : "https://academy.campusvirtual.com.co/signin"
                    }
                    target="_blank"
                  >
                    {idx === 0 ? "Comienza" : "Aplica"}
                  </ButtonGreen>
                  <Button
                    onClick={() => handleClickOpen(title)}
                    sx={{ color: "#000" }}
                  >
                    {idx === 0
                      ? "Ver resumen de contenidos"
                      : "Ver Plan de Estudios"}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        )
      )}
    </Container>
  );
};

export default Course;
