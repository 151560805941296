import React, { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import getData from "../Helpers/getData";

import Navbar from "../Common/Navbar";
import Home from "../Containers/Home";
import Footer from "../Common/Footer";
import styled from "@emotion/styled";
import Academy from "../Containers/Academy";
import Factory from "../Containers/Factory";
import Whatsapp from "../Common/Whatsapp";
import TaxRegimen from "../Components/TaxRegimen";

import { data as db } from "../mocks/db"

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const AppRoutes = () => {
  const [data, setData] = useState({});

  const getDataHome = async () => {
    setData(db.principalPageData)
    // try {
    //   const resp = await getData(
    //     `${process.env.REACT_APP_API_URL_DEV}principalPageData`
    //   );
    //   setData(resp);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    getDataHome();
  }, []);

  //   useEffect(() => {
  //     TagManager.initialize({ gtmId:  })
  //   }, [])

  return (
    <BrowserRouter>
      <Navbar props={data?.headerContent} />
      <Offset></Offset>
      <Whatsapp />
      <Routes>
        {/* Rutas Publicas */}
        <Route path="/" element={<Home props={data} />} />
        <Route path="/academy" element={<Academy props={data} />} />
        <Route path="/factory-soft" element={<Factory />} />
        <Route path="/tax-regimen" element={<TaxRegimen />} />

        {/* Redireccionamiento */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRoutes;
