import React from 'react';
import { useNavigate } from "react-router-dom";

import { Button, Container, Typography } from '@mui/material';
import styled from '@emotion/styled';

const ButtonGreen = styled(Button)(({ theme }) => ({
    display: 'inherit',
    width: 'max-content',
    margin: '1rem auto 2rem',
    borderRadius: '10rem',
    padding: '0.8rem 3rem',
    fontSize: theme.typography.h5.fontSize,
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
        margin: '0.5rem auto 2.5rem'
    }
}))

const AgileTitle = ({ props }) => {
    let navigate = useNavigate();

  return (
    <Container id='agileTitle'>
        <Typography
            sx={{
                backgroundColor: '#00a7d3',
                width: 'fit-content',
                margin: '0 auto',
                padding: '3px 10px',
                color: 'white',
                textAlign: 'center'
            }}
            variant="h4"
            color="initial"
        >
            Agile Innova Academy
        </Typography>
        <br />
        <Typography
            margin={'1rem 0 4rem'}
            textAlign='justify'
            fontWeight={400}
            variant="h6"
            color="initial"
        >
            { props }
        </Typography>
        <ButtonGreen
            onClick={() => navigate('/academy')}
            color='secondary'
            variant='contained'
        >
            Ver mas
        </ButtonGreen>
    </Container>
  )
}

export default AgileTitle;