import React from 'react';

import { Box, Fab } from '@mui/material'
import { WhatsApp } from '@mui/icons-material';

const Whatsapp = () => {
  return (
    <Box
        sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1
        }}
    >
        <Fab color="secondary" aria-label="whatsapp" href='https://api.whatsapp.com/send?phone=+573217031795&text=Hola,%20quiero%20saber%20m%C3%A1s%20sobre%20Agile%20Innova' target='_blank'>
            <WhatsApp />
        </Fab>
    </Box>
  )
}

export default Whatsapp