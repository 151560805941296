import React from 'react';

import { Button, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles'

const Img = styled('img')(({ theme } ) => ({
    width: '27rem',
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'block',
        margin: 'auto'
    }
}))

const Title = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.h5.fontSize,
    color: 'white',
    fontWeight: 700,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        textAlign: 'start'
    }
}))

const SubTitle = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.subtitle1.fontSize,
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        textAlign: 'start',
        fontSize: theme.typography.h6.fontSize,

    }
}))

const ButtonGreen = styled(Button)(({ theme }) => ({
    display: 'inherit',
    width: 'max-content',
    margin: '2rem auto 0',
    borderRadius: '10rem',
    padding: '0.8rem 3rem',
    fontSize: theme.typography.h5.fontSize,
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
        margin: '2rem 0 0'
    }
}))

const FirstContent = ({ props, view }) => {

  return (
    <Container
        maxWidth='100%'
        sx={{
            paddingTop: '5rem',
            paddingBottom: { xs: '1rem', md: '0' },
            backgroundColor: 'rgb(0, 167, 211)',
            margin: "0 auto"
        }}
    >
        <Grid
            container
            justifyContent='center'
            alignItems="center"
            sx={{
                maxWidth: '1150px',
                margin: '0 auto'

            }}
        >
            <Grid item xs={12} md={6} >
                <Title variant="h5">
                    { props?.title }
                </Title>
                <br />
                {view &&
                <SubTitle
                variant="subtitle1"
            >
                { props?.description }
            </SubTitle>
                }
                
                <ButtonGreen
                    target='_blank'
                    href={ props?.btnUrl }
                    color='secondary'
                    variant='contained'
                >
                    { props?.btnAction }
                </ButtonGreen>
            </Grid>

            <Grid item xs={12} md={6}>
                <Img src={ props?.image } />
            </Grid>
        </Grid>
    </Container>
  )
}

export default FirstContent;