import React, { useContext, useEffect } from "react";
import BoxImgtext from "../../Common/BoxImgText";
// import Dialog from '../../Common/Dialog';

import Separator from "../../Common/Separator";
import Amounts from "../../Components/Amounts";
import FirstContent from "../../Common/FirstContent";
import MagicScroll from "../../Components/MagicScroll";
import TimeLine from "../../Components/TimeLine";
import Training from "../../Components/Training";
import AgileTitle from "../../Components/AgileTitle";
import { NavContext } from "../../Context/UseContext";
import { useLocation } from "react-router-dom";
import Carrousel from '../../Components/Carrousel/'
// import Dialog from '../../Common/Dialog';

const Home = ({ props }) => {
  const { setNav } = useContext(NavContext);
  const location = useLocation();
  useEffect(() => {
    setNav(0);
    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
  }, [location, setNav]);

  return (
    <main>
      {/* <Dialog /> */}
      <FirstContent props={props?.mainContent} />
      <Separator />
      <Carrousel />
      <AgileTitle props={props?.mainContent?.description} />
      <Training props={props?.training2} />
      <BoxImgtext props={props?.benefit} />
      <Amounts props={props?.amounts} />
      <BoxImgtext props={props?.experience} title="Lo que vivirás" />
      <TimeLine props={props?.timeline2} />
      <MagicScroll props={props?.allies} view={true} />
    </main>
  );
};

export default Home;
