import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Container, Typography } from '@mui/material';

const MagicScroll = ({ props, view }) => {
  return (
    <Container maxWidth='xl' sx={{ marginTop: '5rem' }}>
      {view && 
      <Typography
          sx={{
            backgroundColor: '#00a7d3',
            width: 'fit-content',
            margin: '0 auto',
            padding: '3px 10px',
            color: 'white'
          }}
          variant="h5"
          fontWeight={400}
          textAlign='center'
          color="initial"
        >
            Aliados
        </Typography> 
        }
        <br />
        <br />
        <Swiper
            breakpoints={{
                0: {
                  slidesPerView: 2,
                  slidesPerGroup: 1,
                  spaceBetween: 10,
                },
                600: {
                  slidesPerView: 3,
                  slidesPerGroup: 1,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 4,
                  slidesPerGroup: 1,
                  spaceBetween: 30,
                },
                1200: {
                  slidesPerView: 5,
                  slidesPerGroup: 1,
                  spaceBetween: 40,
                },
                1536: {
                  slidesPerView: 6,
                  slidesPerGroup: 2,
                  spaceBetween: 50,
                }
              }}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            className="mySwiper"
        >
                {props?.map(({name, image}, idx) => (
                    <SwiperSlide
                        key={ idx }
                    >
                        <img
                            src={ image }
                            alt={ name }
                            height='55'
                            style={{ margin: 'auto', display: 'block'}}
                        />
                    </SwiperSlide>))}
        </Swiper>
    </Container>
    
  )
}

export default MagicScroll