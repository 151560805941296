import { createTheme } from "@mui/material/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: "#FFDE59",
            light: "#fef0b7",
            dark: "#ffbe1a",
            contrastText: "#737373"
        },
        secondary: {
            main: "#32ce66",
            light: "#c1eeca",
            dark: "#00b43e",
            contrastText: "#FFF"
        }
    },
    typography: {
        fontFamily: "Now, sans-serif"
    }
})

export default theme;