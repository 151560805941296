import React from "react";

import styled from "@emotion/styled";
import { Box, Container, Grid, Typography, Button } from "@mui/material";

import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";

const Img = styled("img")({
  width: "17rem",
  filter: "opacity(50%)",
  ":-webkit-filter": "opacity(50%)",
});

const Span = styled('span')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '0.5rem',
  marginBottom: '0.5rem'
})

const ButtonGreen = styled(Button)(({ theme }) => ({
  display: 'inherit',
  width: 'max-content',
  margin: '1.5rem auto 0',
  borderRadius: '10rem',
  padding: '0.7rem 2rem',
  fontSize: theme.typography.body1.fontSize,
  textTransform: 'none',
  [theme.breakpoints.up('md')]: {

  }
}))

const Training = ({ props }) => {
  let navigate = useNavigate();
  return (
    <Box id='training'>
      <Container>
        {/* <Typography
          sx={{
            backgroundColor: '#00a7d3',
            width: 'fit-content',
            margin: '0 auto',
            padding: '3px 10px',
            color: 'white'
          }}
          variant="h4"
          textAlign="center"
          fontWeight={400}
        >
          Entrenamientos
        </Typography> */}
        <Grid container justifyContent="center" alignItems="center">
          {props?.initial?.map(
            ({ title, time, swing, next, option, image, id }, idx) => (
              <Grid
                item
                xs={12}
                md={6}
                key={idx}
                sx={{
                  width: "min-content",
                  height: { xs: 'auto', md: 515 },
                  maxHeight: '700px',
                  margin: "2rem 0 0",
                  display: { xs: 'initial', md: 'flex' },
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                {/* Contenedor de la imagen */}
                <Box
                  sx={{
                    position: "relative",
                    width: "min-content",
                    margin: { xs: "0 auto" },
                  }}
                >
                  <Img src={image} alt={title} />
                  <Typography
                    variant="h5"
                    textAlign="center"
                    fontWeight={500}
                    sx={{
                      position: "absolute",
                      top: "40%",
                      left: "3.2rem",
                    }}
                  >
                    { parse(title) }
                  </Typography>
                </Box>
                {/* Contenedor del body */}
                <Box
                  sx={{
                    width: 'fit-content',
                    maxWidth: '370px',
                    margin: '0 auto',
                    // backgroundColor: 'brown'
                  }}
                >
                  <Span>
                    <img src='https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Duraci%C3%B3n_avf8us.png' alt='time' width={25} />
                    <Typography variant="body1" color="initial">
                      { time }
                    </Typography>
                  </Span>
                  <Span>
                    <img src='https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Intensidad_max3pg.png' alt='swing' width={25} />
                    <Typography variant="body1" color="initial">
                      { swing }
                    </Typography>
                  </Span>
                  <Span>
                    <img src='https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Tasa_de_empleo_meh93b.png' alt='swing' width={25} />
                    <Typography variant="body1" color="initial">
                      { option }: { next }
                    </Typography>
                  </Span>
                  <ButtonGreen
                    onClick={() => navigate(`/academy#${id}`)}
                    variant="contained"
                    color="secondary"
                  >
                    Ver más
                  </ButtonGreen>
                </Box>
              </Grid>
            )
          )}
          {props?.official?.map(
            ({ title, time, swing, next, option, image, id }, idx) => (
              <Grid
                item
                xs={12}
                md={4}
                key={idx}
                sx={{
                  width: "min-content",
                  height: { xs: 'auto', md: 515 },
                  maxHeight: '700px',
                  margin: "2rem 0 0",
                  display: { xs: 'initial', md: 'flex' },
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                {/* Contenedor de la imagen */}
                <Box
                  sx={{
                    position: "relative",
                    width: "min-content",
                    margin: { xs: "0 auto" },
                  }}
                >
                  <Img src={image} alt={title} />
                  <Typography
                    variant="h5"
                    textAlign="center"
                    fontWeight={500}
                    sx={{
                      position: "absolute",
                      top: "40%",
                      left: "3.2rem",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
                {/* Contenedor del body */}
                <Box
                  sx={{
                    width: 'fit-content',
                    maxWidth: '370px',
                    margin: '0 auto',
                  }}
                >
                  <Span>
                    <img src='https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Duraci%C3%B3n_avf8us.png' alt='time' width={25} />
                    <Typography variant="body1" color="initial">
                      { time }
                    </Typography>
                  </Span>
                  <Span>
                    <img src='https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Intensidad_max3pg.png' alt='swing' width={25} />
                    <Typography variant="body1" color="initial">
                      { swing }
                    </Typography>
                  </Span>
                  <Span>
                    <img src='https://res.cloudinary.com/dpkaiokho/image/upload/v1657889273/Agile%20Innova/iconos/Tasa_de_empleo_meh93b.png' alt='swing' width={25} />
                    <Typography variant="body1" color="initial">
                      { option }: { next }
                    </Typography>
                  </Span>
                  <ButtonGreen
                    onClick={() => navigate(`/academy#${id}`)}
                    variant="contained"
                    color="secondary"
                  >
                    Ver más
                  </ButtonGreen>
                </Box>
              </Grid>
            )
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Training;
