import React, { useContext, useEffect, useState } from 'react';
import BoxImgtext from '../../Common/BoxImgText';
import FirstContent from '../../Common/FirstContent';
import Separator from '../../Common/Separator';
import MagicScroll from '../../Components/MagicScroll';
import { NavContext } from '../../Context/UseContext';
import getData from '../../Helpers/getData';
import { useLocation } from 'react-router-dom'

import { data as db } from "../../mocks/db"

const Factory = () => {
    const [data, setData] = useState({})
    const { setNav } = useContext( NavContext );
    const location = useLocation()
    

    const getDataFactory = async() => {
        setData(db['factory-soft'])
    //     try {
    //         const resp = await getData(`${process.env.REACT_APP_API_URL_DEV}factory-soft`);
    //         setData( resp );
    //     } catch (error) {
    //         console.log( error );
    //     }  
    }

    useEffect(() => {
        getDataFactory();
        setNav(2);
        if(location.hash === ''){
            window.scrollTo(0, 0)
        }
    }, [location, setNav])

  return (
    <>
           <FirstContent props={ data?.factoryContent } view={ true } />
           <Separator />
           <BoxImgtext props={ data?.areas } />
           <MagicScroll props={ data?.allies } />
    </>
  )
}

export default Factory;