import React, { useEffect, useState } from 'react'

import { Container, Link, Typography } from '@mui/material'
import getData from '../../Helpers/getData';

import { data as db } from "../../mocks/db"

const TaxRegimen = () => {
    const [data, setData] = useState(null);

    const getDataAcademy = async() => {
        setData(db.taxRegimen);
        // try {
        //     const resp = await getData(`${process.env.REACT_APP_API_URL_DEV}taxRegimen`);
        //     setData( resp );
        // } catch (error) {
        //     console.log( error );
        // }
    }

    useEffect(() => {
        getDataAcademy()
    })
    

  return (
    <Container maxWidth='lg'>
        <Typography variant="h5" color="initial" mt='2rem'>
            { data?.title }
        </Typography>
        <Typography variant="subtitle1" color="initial" mt='1rem'>
            { data?.description }
        </Typography>
        <ul>
        {
                data?.years.map( ({ button, urlButton }, idx ) => (
                    <li key={ idx }>
                        <Link
                            target='_blank'
                            href={ urlButton }
                            sx={{
                                color: '#000',
                                textDecoration: 'none',
                                fontSize: '1.5rem'
                            }}
                        >
                            { button }
                        </Link>
                    </li>
                ))
            }
        </ul>
    </Container>
  )
}

export default TaxRegimen