import React from 'react';

import { Box, Container, Typography } from '@mui/material';

const Amounts = ({ props }) => {
  return (
    <Container
      sx={{
        marginTop: '5rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '1.5rem 1rem'
        }}
      >
        {props?.map(({ amount, description }, idx ) => (
          <Box
            key={ idx }
            maxWidth='366px'
          >
            <Typography
              variant="h4"
              color="initial"
              textAlign='center'
              fontWeight={700}
            >
              { amount }
            </Typography>

            <Typography
              variant="subtitle1"
              color="initial"
              textAlign='center'
            >
              { description }
            </Typography>
          </Box>
        ))}
      </Box>
    </Container>
  )
}

export default Amounts;