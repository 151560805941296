import React, { useContext, useEffect, useState } from 'react';
import Course from '../../Components/Course';
import Information from '../../Components/Information';
import Modal from '../../Components/Modal';
import Tecnologies from '../../Components/Tecnologies';
import { NavContext } from '../../Context/UseContext';
import getData from '../../Helpers/getData';
import { useLocation } from 'react-router-dom'

import { data as db } from "../../mocks/db"

const Academy = () => {
    const [data, setData] = useState({})
    const [open, setOpen] = useState({ bool: false });
    const { setNav } = useContext( NavContext );
    const location = useLocation()
    

    const getDataAcademy = async() => {
        setData(db.academy)
        // try {
        //     const resp = await getData(`${process.env.REACT_APP_API_URL_DEV}academy`);
        //     setData( resp );
        // } catch (error) {
        //     console.log( error );
        // }
    }

    useEffect(() => {
        getDataAcademy();
        setNav(1);
        if(location.hash === ''){
            window.scrollTo(0, 0)
        }
    }, [location, setNav])

    return (
        <>
            <Tecnologies props={ data?.tecnologies } image={ data?.image } />
            <Information props={ data?.information } />
            <Course props={ data?.information?.courses } setOpen={setOpen} />
            <Modal open={ open } setOpen={setOpen} />
        </>
    )
}

export default Academy;