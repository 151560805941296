import React from 'react';

import { Container } from '@mui/material';
import styled from '@emotion/styled';
import MagicScroll from '../MagicScroll';

const Img = styled('img')(({ theme }) => ({
    width: '27rem',
    display: 'block',
    margin: '5rem auto -5rem',
    [theme.breakpoints.down('md')]: {
        width: '17rem'
    }
}))

const Tecnologies = ({ props, image }) => {
  return (
    <Container sx={{ marginBottom: { xs: 10, md: 30}}}>
            <Img src={ image } alt='logo academy' />
            <MagicScroll props={props} />
    </Container>
  )
}

export default Tecnologies;